import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import ImageEditor from '@uppy/image-editor'
import Compressor from '@uppy/compressor'
// import ThumbnailGenerator from '@uppy/thumbnail-generator'
import ActiveStorageUpload from "./uppy_active_storage";

import Catalan from '@uppy/locales/lib/es_ES'
import English from '@uppy/locales/lib/en_US'
import Spanish from '@uppy/locales/lib/es_ES'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'

const UppyLocales = {
  "ca": Catalan,
  "en": English,
  "es": Spanish
}

export default class TrixUploader {
  constructor(attachment, element) {
    this.attachment = attachment;

    this.element = element;

    this.uppy = new Uppy({
      autoProceed: false,
      allowMultipleUploadBatches: false,
      locale: UppyLocales[I18n.locale],
      restrictions: {
        allowedFileTypes: ["image/*"]
      },
    });

    this.uppy.use(Dashboard, {
      // metaFields: [
      //   { id: 'name', name: 'Name', placeholder: 'file name' },
      //   { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
      // ],
      waitForThumbnailsBeforeUpload: true,
      autoOpen: "imageEditor"
    });

    this.uppy.use(ImageEditor, {
      quality: 0.8,
    });

    // this.uppy.use(ThumbnailGenerator, {
    //   thumbnailWidth: 200,
    //   // thumbnailHeight: 200 // optional, use either width or height,
    //   waitForThumbnailsBeforeUpload: true,
    // });

    this.uppy.use(Compressor, {
      quality: 0.8,
    });

    this.uppy.use(ActiveStorageUpload, {
      directUploadUrl: this.directUploadUrl
    });

    this.uppy.on("file-editor:complete", (updatedFile) => {
      this.uppy.getPlugin('Dashboard').closeModal();
    });

    this.uppy.on("dashboard:modal-closed", (ev) => {
      this.uppy.upload();
    });

    // this.uppy.on('upload', (data) => {
    //   // data object consists of `id` with upload ID and `fileIDs` array
    //   // with file IDs in current upload
    //   // data: { id, fileIDs }
    //   // console.log(`Starting upload ${id} for files ${fileIDs}`)
    //   this.uppy.getPlugin('Dashboard').closeModal();
    // });

    this.uppy.on('progress', (progress) => {
      // progress: integer (total progress percentage)
      this.attachment.setUploadProgress(progress)
    });

    this.uppy.on('upload-success', (file, uploadResp) => {
      // uploadResponse: { status: 'success', blob: {}}
      const { blob } = uploadResp;

      this.attachment.setAttributes({
        sgid: blob.attachable_sgid,
        url: this.createBlobUrl(blob.signed_id, blob.filename)
      });
    });

    this.uppy.addFile({
      name: attachment.file.name,
      type: attachment.file.type,
      data: attachment.file,
      meta: {
        relativePath: attachment.file.webkitRelativePath,
      },
      source: 'Local',
      isRemote: false,
    });
  }

  open() {
    this.uppy.getPlugin('Dashboard').openModal();
  }

  createBlobUrl(signedId, filename) {
    return this.blobUrlTemplate
      .replace(":signed_id", signedId)
      .replace(":filename", encodeURIComponent(filename))
  }

  get directUploadUrl() {
    return this.element.dataset.directUploadUrl
  }

  get blobUrlTemplate() {
    return this.element.dataset.blobUrlTemplate
  }
}
