// https://github.com/excid3/uppy-activestorage-upload

import { BasePlugin } from '@uppy/core';
import { filterNonFailedFiles, filterFilesToEmitUploadStarted } from '@uppy/utils/lib/fileFilters'
import { RateLimitedQueue, internalRateLimitedQueue } from '@uppy/utils/lib/RateLimitedQueue'
import EventTracker from '@uppy/utils/lib/EventTracker'
import ProgressTimeout from '@uppy/utils/lib/ProgressTimeout'
import { DirectUpload } from '@rails/activestorage';

let idCounter = 0;

export default class ActiveStorageUpload extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts);

    this.id = opts.id || 'ActiveStorageUpload';
    this.title = opts.title || 'ActiveStorageUpload';
    this.type = 'uploader';
    this.token = opts.token || '';
    this.attachmentName = opts.attachmentName || ''

    const defaultOptions = {
      limit: 5,
      timeout: 30 * 1000,
      directUploadUrl: null,
    };

    this.opts = { ...defaultOptions, ...opts }

    // Simultaneous upload limiting is shared across all uploads with this plugin.
    if (internalRateLimitedQueue in this.opts) {
      this.requests = this.opts[internalRateLimitedQueue]
    } else {
      this.requests = new RateLimitedQueue(this.opts.limit)
    }

    this.uploaderEvents = Object.create(null)
  }

  getOptions(_file) {
    const opts = {
      ...this.opts
    }

    return opts;
  }

  async #upload(file, current, total) {
    const opts = this.getOptions(file);

    this.uppy.log(`uploading ${current} of ${total}`);
    return new Promise((resolve, reject) => {
      const id = ++idCounter;
      const data = file.data;

      const directUpload = new DirectUpload(data, this.opts.directUploadUrl, {
        directUploadWillCreateBlobWithXHR: (_xhr) => {
          this.uppy.log(`[ActiveStorage] ${id} started`)
        },
        directUploadWillStoreFileWithXHR: (xhr) => {
          xhr.upload.addEventListener('progress', (ev) => {
            this.uppy.log(`[ActiveStorage] ${id} progress: ${ev.loaded} / ${ev.total}`);
            // Begin checking for timeouts when progress starts, instead of loading,
            // to avoid timing out requests on browser concurrency queue
            timer.progress()

            if (ev.lengthComputable) {
              this.uppy.emit('upload-progress', file, {
                uploader: this,
                bytesUploaded: ev.loaded,
                bytesTotal: ev.total,
              });
            }
          });
        }
      });

      this.uploaderEvents[file.id] = new EventTracker(this.uppy)
      let queuedRequest

      const timer = new ProgressTimeout(opts.timeout, () => {
        const error = new Error(this.i18n('uploadStalled', { seconds: Math.ceil(opts.timeout / 1000) }))
        this.uppy.emit('upload-stalled', error, [file])
      })

      queuedRequest = this.requests.run(() => {
        // here we have the two xhr callbacks in one method (load and error)
        directUpload.create((errorMessage, blob) => {
          if (errorMessage) {
            // https://github.com/transloadit/uppy/blob/90f7fb91974502c29ce935a0ed4befdecd6b0f64/packages/%40uppy/xhr-upload/src/index.js#L293
            this.uppy.log(`[ActiveStorage] ${id} errored`)
            timer.done()
            queuedRequest.done()
            if (this.uploaderEvents[file.id]) {
              this.uploaderEvents[file.id].remove()
              this.uploaderEvents[file.id] = null
            }

            const error = new Error(errorMessage)
            this.uppy.emit('upload-error', file, error)
            return reject(error)
          } else {
            // https://github.com/transloadit/uppy/blob/90f7fb91974502c29ce935a0ed4befdecd6b0f64/packages/%40uppy/xhr-upload/src/index.js#L254
            this.uppy.log(`[ActiveStorage] ${id} finished`)
            timer.done()
            queuedRequest.done()
            if (this.uploaderEvents[file.id]) {
              this.uploaderEvents[file.id].remove()
              this.uploaderEvents[file.id] = null
            }

            const uploadResp = {
              status: 'success',
              blob: blob,
            };

            this.uppy.emit('upload-success', file, uploadResp);

            return resolve(file);
          }
        });

        return () => {
          timer.done();
          directUpload.abort && directUpload.abort();
        }
      })

      this.onFileRemove(file.id, () => {
        queuedRequest.abort()
        reject(new Error('File removed'))
      })

      this.onCancelAll(file.id, ({ reason }) => {
        if (reason === 'user') {
          queuedRequest.abort()
        }
        reject(new Error('Upload cancelled'))
      })
    });
  }

  async #uploadFiles(files) {
    await Promise.allSettled(files.map((file, i) => {
      const current = parseInt(i, 10) + 1;
      const total = files.length;

      return this.#upload(file, current, total)
    }));
  }

  onFileRemove (fileID, cb) {
    this.uploaderEvents[fileID].on('file-removed', (file) => {
      if (fileID === file.id) cb(file.id)
    })
  }

  onCancelAll (fileID, eventHandler) {
    this.uploaderEvents[fileID].on('cancel-all', (...args) => {
      if (!this.uppy.getFile(fileID)) return
      eventHandler(...args)
    })
  }

  #handleUpload = async (fileIDs) => {
    if (fileIDs.length === 0) {
      this.uppy.log('[ActiveStorage] No files to upload!');
      return;
    }

    // No limit configured by the user, and no RateLimitedQueue passed in by a "parent" plugin
    // (basically just AwsS3) using the internal symbol
    if (this.opts.limit === 0 && !this.opts[internalRateLimitedQueue]) {
      this.uppy.log(
        '[ActiveStorage] When uploading multiple files at once, consider setting the `limit` option (to `10` for example), to limit the number of concurrent uploads, which helps prevent memory and network issues: https://uppy.io/docs/xhr-upload/#limit-0',
        'warning',
      )
    }

    this.uppy.log('[ActiveStorage] Uploading...');
    const files = this.uppy.getFilesByIds(fileIDs)

    const filesFiltered = filterNonFailedFiles(files)
    const filesToEmit = filterFilesToEmitUploadStarted(filesFiltered)
    this.uppy.emit('upload-start', filesToEmit)

    await this.#uploadFiles(filesFiltered)
  }

  install() {
    this.uppy.addUploader(this.#handleUpload);
  }

  uninstall() {
    this.uppy.removeUploader(this.#handleUpload);
  }
};
