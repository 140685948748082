import { Controller } from "@hotwired/stimulus"

import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import ImageEditor from '@uppy/image-editor'
import Compressor from '@uppy/compressor'

import Catalan from '@uppy/locales/lib/ca_ES'
import English from '@uppy/locales/lib/en_US'
import Spanish from '@uppy/locales/lib/es_ES'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'

const UppyLocales = {
  "ca": Catalan,
  "en": English,
  "es": Spanish
}

export default class extends Controller {
  static targets = [ "input", "purgeButton", "purgeField", "preview" ]

  static values = {
    allowedFileTypes: Array,
    aspectRatio: String,
    hasImage: Boolean,
    maxFileSize: Number,
  }

  connect() {
    this.uppy = new Uppy({
      autoProceed: false,
      allowMultipleUploadBatches: false,
      locale: UppyLocales[I18n.locale],
      restrictions: {
        allowedFileTypes: this.allowedFileTypesValue,
        maxFileSize: this.maxFileSizeValue * 1024 * 1024, // MB
      },
    });

    this.uppy.use(Dashboard, {
      // metaFields: [
      //   { id: 'name', name: 'Name', placeholder: 'file name' },
      //   { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
      // ],
      waitForThumbnailsBeforeUpload: false,
      showSelectedFiles: false,
      disableThumbnailGenerator: true,
      disableStatusBar: true,
      singleFileFullScreen: true,
      proudlyDisplayPoweredByUppy: false,
      autoOpen: "imageEditor"
    });

    this.uppy.use(ImageEditor, {
      quality: 0.8,
      actions: {
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      },
      cropperOptions: {
        aspectRatio: this.aspectRatioValue,
      }
    });

    this.uppy.use(Compressor, {
      quality: 0.8,
    });

    this.uppy.on("file-editor:complete", (updatedFile) => {
      this.uppy.getPlugin('Dashboard').closeModal();
      this.previewTarget.src = URL.createObjectURL(updatedFile.data);
      this.purgeFieldTarget.value = "false";
      this.hasImageValue = true;

      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(updatedFile.data);
      this.inputTarget.files = dataTransfer.files;
    });
  }

  open() {
    // reset Uppy state so user can select same image again
    this.uppy.cancelAll();
    this.uppy.getPlugin('Dashboard').openModal();
  }

  purgeImage() {
    this.previewTarget.src = "";
    this.inputTarget.value = "";
    this.purgeFieldTarget.value = "true";
    this.hasImageValue = false;
  }

  hasImageValueChanged(current, _old) {
    if (current) {
      this.purgeButtonTarget.classList.remove("hidden");
    } else {
      this.purgeButtonTarget.classList.add("hidden");
    }
  }
}
