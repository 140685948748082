import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  updateAmountInCents(event) {
    let value = Number(event.target.value.replace(",","."));

    let amountInInteger = value || 0;
    let amountInCents = (amountInInteger * 100).toFixed();

    this.inputTarget.value = amountInCents;
  }
}
